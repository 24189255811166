body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: hsla(0, 0%, 87.8%, .41) !important; */
  height: 100%;
}
#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root {
  --dark-grey: #3c3c3c;
  --custom-green: #1e9d61;
  --orange: #ff8a00;
  --darkblue: rgba(75, 75, 75, 0.45);
}


.bg-main {
  position: relative;
  height: 100%;
  background-image: url(./images/fondo1_movil.png);
  background-image: url(./images/fondo_burger.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg-burger-before {
  display: flex;
  /*background: linear-gradient(180deg, rgba(0, 0, 0, .9), hsla(0, 0%, 100%, .1) 45%, #000);*/
  height: 100vh;
}

.container-footer-option {
  align-self: flex-end;
  height: 180px;
  width: 100%;
}

.bg-main-home {
  height: 100vh;
}

.main-header {
  z-index: 2000;
  /* background-color: rgba(224, 224, 224,1); */
  background-color: rgba(255, 255, 255,1);
}

.container-header-location {
  position: absolute;
  align-self: flex-start;
  height: 300px;
  width: 100%;

}

.container-body-icon {
  position: absolute;
  bottom: 200px;
  height: 80px;
  width: 100%;
}

.container-footer-location {
  align-self: flex-end;
  height: 220px;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 20px;
  
  /* background: rgb(17,16,16);
background: linear-gradient(0deg, rgba(17,16,16,1) 0%, rgba(165,165,165,0) 44%, rgba(156,154,154,1) 61%, rgba(0,0,0,0) 100%); */
}

/* .header-blur {
  position: absolute;
  width: 100%;
  height: 150px;
  filter: blur(10px);

  background: rgb(215,215,215);
background: linear-gradient(180deg, rgba(215,215,215,1) 0%, rgba(210,210,210,0.2666272795602177) 30%, rgba(255,255,255,0) 100%);
  z-index: 2000;
}

.footer-blur {
  position: absolute;
  width: 100%;
  height: 150px;
  
  filter: blur(10px); 
  background: rgb(215, 215, 215);
  background: linear-gradient(0deg, rgba(215, 215, 215, .8) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2000;
  bottom: 0;
} */

.card-places {
  background-color: transparent !important;
  border: none !important;
  /* border-radius: 20px !important;
  background-color: hsla(0, 0%, 100%, .55) !important; */
  height: 40px !important;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}


.size-image-header {
  width: 80px;
}

.content-image-category {
  position: relative;
  text-align: center;
}
.content-image-category  img{
  max-width: 100px;
}

@media (min-width: 320px) and (max-width: 400px) {
  .content-image-category-subtitle {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    height: 10px;
    bottom: 0;
    color: #fff;
  }

  .title-category-card {
    font-size: 12px;
    font-weight: 700;
  }

  .fixed-bottom-custom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }

  .btn-add {
    background-color: #fff;
    border: none;
    border-radius: 50px;
    padding: 10px 22px;
    white-space: nowrap;
  }

}

.rounded-lg-10 {
  border-radius: 10px !important;
}


.content-image-category-subtitle {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, .6);
  height: 40px;
  bottom: 0;
  color: rgba(0, 0, 0, .6);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title-category-card {
  font-size: 16px;
  color: #fff;
}

.image-category {
  height: 200px;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover !important;
}

#back-category {
  position: absolute;
  top: 10px;
  margin-bottom: 15px;
  margin-left: 10px;
  font-size: 22px;
  border-radius: 15px;
  color: #000;
}


#title-category, #title-product-modal {
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  margin-left: 10px;
  padding: 2px 13px 2px 10px;
  background-color: #fff;
  color: #000;
}


#title-product-modal {
  font-size: 20px;
  border: 1px solid #dedede;
  border-radius: 15px;
}

#title-category {
  font-size: 22px;
  border: 1px solid #fff;
  border-radius: 15px;
}

.card, .card-address {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  cursor: pointer;
}

.card {
  border-radius: 0.25rem;
  border: 1px solid hsla(0, 0%, 100%, .3) !important;
  border-bottom-color: rgba(0, 0, 0, .2) !important;
}

.link-category {
  color: var(--grey-dark) !important;
  text-decoration: none !important;
}

.bg-grey {
  background-color: #fcfcfc;
}

.title-product {
  font-weight: 900;
  font-size: 14px;
}

.image-product {
  height: 250px;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover !important;
}

.title-ingredients {
  color: var(--custom-green);
  font-weight: 700;
}

.text-description-product {
  color: rgba(0, 0, 0, .7);
}

.title-group-modifier {
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  letter-spacing: 1px;
}

.title-options-required {
  background-color: #f29100;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
}

.title-optional {
  color: rgba(0, 0, 0, .6);
}

.dot {
  min-height: 30px;
  min-width: 30px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 50%;
  display: inline-block;
}

.dot, .dot-active {
  background-color: #fff;
}

.dot-active {
  min-height: 30px;
  min-width: 30px;
  border: 8px solid #ff8a00 !important;
  border: 8px solid var(--golden);
  border-radius: 50%;
}

.bg-black-9 {
  background-color: rgba(0, 0, 0, .9);
}

.bg-black-7 {
  background-color: rgba(0, 0, 0, .7);
}

.bg-black-6 {
  background-color: rgba(0, 0, 0, .6);
}

.page {
  z-index: 3000 !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  width: 0;
  height: 100%;
  overflow: hidden;
  outline: 0;
  transition: all .5s ease-in-out;
}

.page-show {
  position: fixed !important;
  width: 100% !important;
  z-index: 5000 !important;
  border-right: 1px solid rgb(249, 87, 0);
}

.btn-drawer {
  width: 100%;
  background-color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.btn-menu-main {
  background-color: var(--darkblue);
  color: #fff;
  height: 100%;
  cursor: pointer;
  border-radius: 50px;
}

.dot-quantity {
  width: 40px;
  height: 40px;
  background-color: var(--orange);
  border-radius: 50%;
  font-size: 18px;
}

.circle-button, .circle-button-cart {
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
}

.circle-button {
  border-radius: 50%;
  border: 3px solid #fff;
  color: #fff;
}

/* .btn-add-remove {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
} 
*/
.button-style {
  background-color: transparent;
  border: none;
  outline: none !important;
  padding: 0;
}

.btn-add {
  background-color: #ff8a00 !important;
  background-color: var(--golden) !important;
  border: 3px solid #fff !important;
  border-radius: 50px !important;
  padding: 10px 30px;
  color: #fff !important;
}

.square {
  height: 35px;
  width: 35px;
  border: 2px solid rgba(0, 0, 0, .1);
  border-radius: 10px;
  display: inline-block;
  background-color: #fff;
}

.square-active {
  min-height: 100%;
  min-width: 100%;
  border: 8px solid var(--orange);
  border-radius: 10px;
  background-color: #fff;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  animation-direction: alternate-reverse;
}

.product-description-cart {
  font-size: 12px;
  color: #414141;
}

.bg-white-2 {
  background-color: rgba(22, 22, 22, .2);
}

.bg-white-5 {
  background-color: rgba(22, 22, 22, .5);
}

.btn-add {
  background-color: var(--orange) !important;
  border: 3px solid #fff !important;
  border-radius: 50px !important;
  padding: 10px 30px;
  color: #fff !important;
}

.title-options-required {
  background-color: #f29100;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-11 {
  font-size: 11px;
}

.bg-golden {
  background-color: var(--orange) !important;
}

.button-style {
  background-color: transparent;
  border: none;
  outline: none !important;
  padding: 0;
}

.circle-button-cart {
  border-radius: 50%;
  border: 3px solid #1a2d59;
  border: 3px solid black;
  color: #1a2d59;
  color: var(--custom-blue);
}

.div-error {
  border: 1px solid red
}



.scene3d {
  perspective: 900px;
  width: 380px;
  height: 340px;
  margin-left: auto;
  margin-right: auto;
}

.object3d {
  position: absolute;
  width: inherit;
  height: inherit;
  /*top: 20px;*/
  transform-style: preserve-3d;
}

.face3d {
  position: absolute;
  left: 130px;
  top: 15px;
}

#im1 {

  width: 120px;
  height: 100px;
  transform: translateZ(1px);
}

#im2 {
  position: absolute;
  width: 120px;
  height: 100px;
  transform: translateZ(5px);
}

.scene3d.begin {
  /* perspective: 100000px; */
  perspective: 10px;
}

.scene3d.end {
  perspective: 100px;
}

#obj1 {
  animation: theRotate 4s linear infinite;
}

@keyframes theRotate {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.text-gray-light {
  color: #666363
}

.item-category div {
  color: white;
}

.item-category {
  background-color: rgba(255, 255, 255, .3);
  border-radius: 15px;
}

.item-category-active {
  background-color: rgba(255, 255, 255, .5);
  color: #000;
  border-radius: 15px;
}

a:hover {
  background-color: transparent;
}

.bg-discount{
  background-image: url(./images/background_smooth.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.padding-top-custom{
  padding-top: 20%;
}

.label-discount{
  color: white;
}

.btn-custom{
  height: 45px !important;
}

@media only screen and (max-width: 600px) {
  .padding-top-custom{
    padding-top: 350px;
  }
}

.title-main-menu{
  color: #666363;
  letter-spacing: 1px;
}

.div-selected{
  width: 100%;
  height: 60px;
  cursor: pointer;
}

.div-selected-content{
  background-color: #666363;
  width: 100%;
  height: 60%;
  border-radius: 50px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.active-bg{
  background-color: #fd7e14;
}

.div-selected-img{
  background-color: rgb(230, 228, 228);
  width: 40px;
  height: 38px;
    clip-path: circle(50%);
  position: absolute;
  left: 0;
  -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.active-img{
  left: 70%;
}

.div-selected-text{
  padding-top: 10px;
  width: 100%;
  height: 40%;
  text-align: center;
}

.container-options-branch{
  position:fixed;
  background-color: rgba(0, 0, 0, .4);
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.container-options-branch-content{
  position:absolute;
  background-color: white;
  width: 99%;
  height: 250px;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 2000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: block !important;
}

.active-branch{
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  width: 0%;
  height: 0%;
  display: block;
}

.container-options-branch-content .content-branch{
  display: inline-block;
}

.active-branch .content-branch{
  display: none;
}

.img-menu{
  width: 40px;
}

.container-banner{
  display: grid;
  grid-auto-flow: column;
  /* grid-auto-columns: 21%; */
  overflow-x: auto;
}

.item-banner{
  width: 250px;
  height: 100px;
  background-color: red;
  border-radius: 20px;
  margin-left: 10px;
}

.btn-add-remove{
  width: 100px;
  height: 35px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.btn-add-remove-white{
  border:1px solid white;
}
.btn-add-remove-black{
  border:1px solid black;
}


.btn-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: inherit;
  text-align: center;
}

.btn-icon-white{
  color: white;
}